import axios from 'axios'
export default {
  methods: {
    async rec_listado_motivo_inicial_micrositio (pMotivoid, motivoId) {
      return new Promise((resolve, reject) => {
        this.loadingMotivo = true
        if (this.items) this.items.motivoprincipales = []
        if (this.itemsLectura) this.itemsLectura.motivosPrincipales = []
        const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoid
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          if (this.items) this.items.motivoprincipales = response.data
          this.items.motivoprincipales.forEach(element => {
            if (element.id === motivoId) {
              this.items.objMotivoQR = element
            }
          })
          if (this.itemsLectura) this.itemsLectura.motivosPrincipales = response.data
          resolve('Motivo Asignado')
        }).catch(error => {
          console.log('error ' + error)
          reject(error)
        }).finally(() => {
          this.loadingMotivo = false
        })
      })
    },
    async rec_listado_submotivo_inicial_micrositio (pMotivoid, submotivoId) {
      return new Promise((resolve, reject) => {
        if (this.loadingMotivoQR) this.loadingMotivoQR = true
        if (this.items_motivosqr) this.items_motivosqr = []
        if (this.itemsLectura) this.itemsLectura.submotivo = []
        const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoid
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          if (this.itemsLectura) this.itemsLectura.submotivo = response.data
          if (this.items_motivosqr) this.items_motivosqr = response.data
          this.items_motivosqr.forEach(element => {
            if (element.id === submotivoId) {
              this.items.objSubMotivoQR = element
            }
          })
          resolve('SubMotivo Asignado')
        }).catch(error => {
          console.log('error ' + error)
          reject(error)
        }).finally(() => {
          this.loadingMotivoQR = false
        })
      })
    },
    rec_listado_motivo (pMotivoid) {
      this.loadingMotivo = true
      if (this.items) this.items.motivoprincipales = []
      if (this.itemsLectura) this.itemsLectura.motivosPrincipales = []
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoid
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        if (this.items) this.items.motivoprincipales = response.data
        if (this.itemsLectura) this.itemsLectura.motivosPrincipales = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMotivo = false
      })
    },
    rec_listado_submotivo (pMotivoid) {
      if (this.loadingMotivoQR) this.loadingMotivoQR = true
      if (this.items_motivosqr) this.items_motivosqr = []
      if (this.itemsLectura) this.itemsLectura.submotivo = []
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoid
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        if (this.itemsLectura) this.itemsLectura.submotivo = response.data
        if (this.items_motivosqr) this.items_motivosqr = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMotivoQR = false
      })
    },
    rec_listado_razon_llamada (pRazonId) {
      this.loadingDetalle = true
      this.items_detalles = []
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pRazonId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_detalles = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    },
    rec_listado_cierre_causal (pMotivoId) {
      this.loadingMotivo = true
      this.items_motivos = []
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_motivos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMotivo = false
      })
    },
    rec_listado_cierre_motivo (pMotivoId) {
      this.loadingSubmotivo = true
      this.items_submotivos = []
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pMotivoId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_submotivos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingSubmotivo = false
      })
    }

  }
}
